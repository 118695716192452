import { useContext } from "react";

import Text from "../elements/Text";
import ButtonText from "../elements/ButtonText";
import ButtonLink from "../elements/ButtonLink";
import InputText from "../elements/InputText";
import Dropdown from "./Dropdown";
import ButtonFileUpload from "../elements/ButtonFileUpload";

import PayPalButton from "./PayPalButton";

import ageList from "../json/ageList";
import { month } from "../json/dateList";
import { day } from "../json/dateList";
import { year } from "../json/dateList";
import { conflicts } from "../json/conflictList";

import { useMemoryFormFunctions } from "../functions/MemoryFormFunctions";

import { AppStateContext } from "../contexts/AppStateContexts";

import { useExternalLink } from "../functions/GenericFunctions";

import RememberenceCard from "./RememberenceCard";

export function useMemoryFormContent() {
    const {        
        changeAppState
    } = useContext(AppStateContext);

    const {
        openNewTab
    } = useExternalLink();

    const {
        familyName,
        surname,
        middleName,
        age,
        dayOption,
        monthOption,
        yearOption,
        ageValue,
        dayValue,
        monthValue,
        yearValue,
        conflictOption,
        conflictValue,
        memoryImgURL,
        audioURL,
        imgName,
        audioName,
        familyNameWarning,
        surnameWarning,
        ageWarning,
        dateWarning,
        conflictWarning,
        imgWarning,
        audioWarning,
        formWarning,        
        canPay,
        handleFamilyName,
        handleSurname,
        handleMiddleName,
        handleSetAge,
        handleSetDay,
        handleSetMonth,
        handleSetYear,
        handleSetConflict,
        handleSetMemoryImage,
        handleSetAudio,
        ValidateForm,
        handleSubmit,
        createOrder,
        onApprove,
        onError
    } = useMemoryFormFunctions()

    const Ages = ageList;
    const Days = day;
    const Months = month;
    const Years = year;
    const Conflicts = conflicts;

    const inputFamilyName = <InputText
        name="Family name field."
        label="What was their family name? (required)"
        labelfont="Medium"
        labelsize="1.2rem"
        type="text"
        placeholder="Family Name"
        value={familyName}
        length="420px"
        visible={true}
        labelvisible={true}
        onChange={handleFamilyName}
    />

    const inputSurname = <InputText
        name="Surname field."
        label="What was their surname? (required)"
        labelfont="Medium"
        labelsize="1.2rem"
        type="text"
        placeholder="Surname"
        value={surname}
        length="420px"
        visible={true}
        labelvisible={true}
        onChange={handleSurname}
    />

    const InputMiddleName = <InputText
        name="Middle name field."
        label="If available, what was their middle name?"
        labelfont="Medium"
        labelsize="1.2rem"
        type="text"
        placeholder="Middle Name"
        value={middleName}
        length="420px"
        visible={true}
        labelvisible={true}
        onChange={handleMiddleName}
    />

    const ageDropdown = <Dropdown 
        label="What age were they when they passed? (required)"
        labelSize="1.2rem"
        defaultOption="0"
        optionList={Ages}
        width="58px"
        height="210px"         
        value={age}
        select={handleSetAge}
    />

    const dateLabel = <Text 
        text="When did they pass? (required)"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const dayDropdown = <Dropdown 
        label="Day"
        labelSize="0.9rem"
        defaultOption="Day?"
        optionList={Days}
        width="120px"
        height="112px"         
        value={dayOption}
        select={handleSetDay}
    />

    const monthDropdown = <Dropdown 
        label="Month"
        labelSize="0.9rem"
        defaultOption="Month?"
        optionList={Months}
        width="120px"
        height="112px"         
        value={monthOption}
        select={handleSetMonth}
    />

    const yearDropdown = <Dropdown 
        label="Year"
        labelSize="0.9rem"
        defaultOption="Year?"
        optionList={Years}
        width="130px"
        height="112px"         
        value={yearOption}
        select={handleSetYear}
    />

    const conflictDropdown = <Dropdown 
        label="Please clarify which conflict they were a victim of. (required)"
        labelSize="1.2rem"
        defaultOption="Conflict?"
        optionList={Conflicts}
        width="400px"
        height="122px"         
        value={conflictOption}
        select={handleSetConflict}
    />

    const uploadImageText = <Text 
        text="Upload an image of the one lost. (4MB limit if available)"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const uploadImage = <ButtonFileUpload 
        text="Upload Image"
        filetypes=".jpg, .jpeg, .png"
        onChange={handleSetMemoryImage}
    />

    const uploadImageName = <Text 
        text={imgName}
        font="SemiBold"
        fontcolor={imgWarning ? "font-yellow" : "font-white"}
        fontsize="1.0rem"
        width="250px"
        visible={true}
    />

    const uploadAudioText = <Text 
        text="Upload a short audio clip of the one lost. (2-minute limit if available)"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const uploadAudio = <ButtonFileUpload 
        text="Upload Audio"
        filetypes=".mp3"
        onChange={handleSetAudio}
    />

    const uploadAudioName = <Text 
        text={audioName}
        font="SemiBold"
        fontcolor={audioWarning ? "font-yellow" : "font-white"}
        fontsize="1.0rem"
        width="185px"
        visible={true}
    />

    const reviewCard = <RememberenceCard 
        hasImage={memoryImgURL ? true : false}
        victimImg={memoryImgURL ? memoryImgURL : ""}
        familyName={familyName}
        surname={surname}
        middlename={middleName}
        age={ageValue}
        conflict={conflictValue}
        hasAudio={audioURL ? true : false}
        audio={audioURL ? audioURL : ""}
        date={`${dayValue} ${monthValue} ${yearValue}`}
        cardID="3"
    />

    const reviewText = <Text 
        text="Please review the rememberence card to ensure you have entered the correct information before proceeding."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="185px"
        visible={true}
    />

    const familyWarningText = <Text 
        text="Please provide their family name."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={familyNameWarning}
    />

    const surnameWarningText = <Text 
        text="Please provide their surname."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={surnameWarning}
    />

    const ageWarningText = <Text 
        text="Please provide the age they passed."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={ageWarning}
    />

    const dateWarningText = <Text 
        text="Please provide the date they passed."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={dateWarning}
    />

    const conflictWarningText = <Text 
        text="Please provide the conflict they were a victim of."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={conflictWarning}
    />

    const formWarningText = <Text 
        text="Please complete the required fields above."
        font="SemiBold"
        fontcolor="font-yellow"
        fontsize="1.0rem"
        width="400px"
        visible={formWarning}
    />

    const contributionText = <Text 
        text={canPay ? "Contribute $5(USD) via PayPal to add the memory." : "A contribution of $5(USD) is required to add this memory. This helps covers server costs, processing fees, and other additional costs."}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const paypalLink = <ButtonLink
        text="PayPal Privacy Statement"
        font="Bold"
        fontsize="0.9rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://www.paypal.com/us/legalhub/privacy-full")} 
    />

    const creditcardNotice = <Text 
        text={<>By paying with your card, you acknowledge that your data will be processed by PayPal subject to the {paypalLink} available at PayPal.com.</>}
        font="SemiBold"
        fontcolor="font-white"
        fontsize="0.9rem"
        width="388px"
        visible={canPay}
    />

    const submitButton = <ButtonText 
        text="Proceed"
        visible={canPay ? false : true}
        onClick={ValidateForm}
    />

    const paymentButton = <PayPalButton         
        onClick={handleSubmit}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
        visible={canPay}
    />

    const enableCookiesText = <Text 
        text="To add a memory, a $5(USD) contribution is needed.
                    To process your request, we use PayPal as our payment processor.
                    They require us to use third-party cookies.
                    Please turn on third-party cookies in our Cookie Policy to make a contribution.
                    We cannot remember the ones lost without your help."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const cookiePolicyButton = <ButtonText 
        text="Cookie Policy"
        visible={true}
        onClick={() => changeAppState("TERMS")}
    />

    return { 
        inputFamilyName, 
        inputSurname, 
        InputMiddleName, 
        ageDropdown, 
        dateLabel, 
        dayDropdown, 
        monthDropdown, 
        yearDropdown, 
        conflictDropdown, 
        uploadImageText,
        uploadImage,
        uploadImageName,
        uploadAudio,
        uploadAudioText,
        uploadAudioName,
        reviewCard, 
        reviewText,
        familyWarningText,
        surnameWarningText,
        ageWarningText,
        dateWarningText,
        conflictWarningText,
        formWarningText,
        contributionText,
        creditcardNotice,
        submitButton,
        paymentButton,
        enableCookiesText,
        cookiePolicyButton
    };
}