const ButtonLink = ({text, font, fontsize, fontcolor, width, visible, onClick}) => {
    const fontstyle = `underline ${font} ${fontcolor}`;

    return (
        <div style={{display: (visible ? "inline" : "none"), maxWidth: width}} onClick={() => onClick()}>
            <span className={fontstyle} style={{fontSize: fontsize}}>{text}</span>
        </div>
    )
}

export default ButtonLink;