import { useFooterContent } from "./Footer-Content";

const Footer = ({ visible }) => {
    const {
        siteDesigner,
        termsLink,
        xLink,
        blueSkyLink
    } = useFooterContent();

    const appearance = "flex absolute vertical-center background-dark gap-auto pad10";
    const linkAppearance = "flex vertical column-justify-right";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none"), minWidth: "95%", bottom: "0px"}}>
            <div>
                {siteDesigner}
            </div>
            <div className={linkAppearance}>
                {termsLink}
                {xLink}
                {blueSkyLink}
            </div>            
        </div>
    )
}

export default Footer;