import { useSuccessPageContent } from "./SuccessPage-Content";

const SuccessPage = ({ visible }) => {
    const {
        successHeader,
        successText,
        returnButton
    } = useSuccessPageContent();

    const appearance = "flex vertical vertical-center horizontal-center pad10 gap10";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none"), minHeight: `calc(100vh - 300px)`}}>
            {successHeader}
            {successText}
            <br />
            {returnButton}
        </div>
    )
}

export default SuccessPage;