import { useContext } from "react";

import Text from "../elements/Text";
import ButtonText from "../elements/ButtonText";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useCookieBannerContent() {
    const {
        toggleBanner,
        toggleCookies,
        changeAppState
    } = useContext(AppStateContext);

    const bannerHeader = <Text 
        text="This site uses third-party cookies."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.8rem"
        width="400px"
        visible={true}
    />

    const bannerText = <Text 
        text="This website requires the use of third-party cookies for processing financial requests with PayPal.
                   By clicking on 'Accept', you consent to the use of third-party cookies on this website for the purpose of using your personal financial data to process a payment for a contribution via PayPal.
                   For more information, please read our Cookie Policy."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="1400px"
        visible={true}
    />

    const acceptButton = <ButtonText 
        text="Accept"
        visible={true}
        onClick={() => {toggleBanner(); toggleCookies()}}
    />

    const declineButton = <ButtonText 
        text="Decline"
        visible={true}
        onClick={() => toggleBanner()}
    />

    const policyButton = <ButtonText 
        text="Policy"
        visible={true}
        onClick={() => {toggleBanner(); changeAppState("TERMS")}}
    />

    return { bannerHeader, bannerText, acceptButton, declineButton, policyButton }
}