import { useContext } from "react";
import ButtonText from "../elements/ButtonText";
import ButtonImage from "../elements/ButtonImage";
import Image from "../elements/Image";
import InputText from "../elements/InputText";
import Text from "../elements/Text";

import Logo from "../images/LostEchoesWordmark.svg";
import BackArrow from "../images/icon-backarrow.svg";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useHeaderContent() {
    const { 
        searchValue,
        changeAppState,
        handleSearchChange
    } = useContext(AppStateContext);

    const logo = <Image 
        image={Logo}
        alt="Lost Echoes Online"
        height=""
        width="350px"
        padding=""
        visible={true}
    />

    const purposeText = <Text 
        text="Remembering the civilians lost to warfare."
        font="Italic"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="350px"
        visible={true}
    />

    const addButton = <ButtonText 
        text="Add a memory..."
        visible={true}
        onClick={() => changeAppState("MEMORY")}
    />

    const searchField = <InputText
        name="search"
        label="Find a memory..."
        labelfont="SemiBoldItalic"
        labelsize="1.0rem"
        type="search"
        placeholder="Family Name"
        value={searchValue}
        length="190px"
        visible={true}
        labelvisible={true}
        onChange={(e) => handleSearchChange(e)}
      />

    const backButton = <ButtonImage         
        image={BackArrow}
        alt="Go back."
        height="30px"
        width="30px"
        padding="0px"
        dynamic={false}
        visible={true}
        onClick={() => changeAppState("WALL")}
    />

    const backText = <Text 
        text="Go back."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="150px"
        visible={true}
    />

    return { logo, purposeText, addButton, searchField, backButton, backText };
}