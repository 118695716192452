import './App.css';
import './stylesheets/bgCards.css';
import './stylesheets/flex.css';
import './stylesheets/fonts-garamond.css';
import './stylesheets/scroller.css';

import { useAppContentNew } from './App-ContentNew';
import { useRef } from 'react';

function App() { 
  const scrollRef = useRef();

  const {
    appBody,
    toTopImage
  } = useAppContentNew(scrollRef);

  const appAppearance = 'flex horizontal-center relative overflow-y-hidden' 

  const imageAppearance = "absolute";

  return (
    <div className={appAppearance} style={{height: "100vh"}}>
      {appBody}
      <div className={imageAppearance} style={{bottom: "90px", right: "20px"}}>
        {toTopImage}
      </div>
    </div>
  )
}

export default App;