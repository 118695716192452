import React from "react";
import { createRoot } from "react-dom/client";

import { AppStateContextProvider } from "./contexts/AppStateContexts";
import { AudioStateContextProvider } from "./contexts/AppStateContexts";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <AppStateContextProvider >
    <AudioStateContextProvider >
      <App />
    </AudioStateContextProvider>    
  </AppStateContextProvider>
)