import { useRef } from "react";
import "../stylesheets/buttonFileUpload.css";

const ButtonFileUpload = ({ text, filetypes, onChange }) => {
    const ref = useRef(null);

    return (
        <div>
            <label className="btnFileUpload" onClick={() => ref.current.click()}>
                {text}
            </label>
            <input style={{display: "none"}} type="file" accept={filetypes} ref={ref} onChange={(e) => onChange(e)}/>
        </div>
    )
}

export default ButtonFileUpload;