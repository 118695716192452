import { useContext } from "react";

import { useMemoryFormContent } from "./MemoryForm-Content";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { AppStateContext } from "../contexts/AppStateContexts";

const MemoryForm = () => {
    const {
        cookies,
        canPay
    } = useContext(AppStateContext);

    const {
        inputFamilyName,
        inputSurname,
        InputMiddleName,
        ageDropdown,
        dateLabel,
        dayDropdown,
        monthDropdown,
        yearDropdown,
        conflictDropdown,
        uploadImageText,
        uploadImage,
        uploadImageName,
        uploadAudioText,
        uploadAudio,
        uploadAudioName,
        reviewCard,
        reviewText,
        familyWarningText,
        surnameWarningText,
        ageWarningText,
        dateWarningText,
        conflictWarningText,
        formWarningText,
        contributionText,
        creditcardNotice,  
        submitButton,    
        paymentButton,
        enableCookiesText,
        cookiePolicyButton
    } = useMemoryFormContent()

    const paypalOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "disable-funding": "paylater",        
    }

    const appearance = "flex vertical gap10 pad10";
    const dateAppearance = "flex vertical-center gap20";
    const reviewAppearance = "flex vertical-center top-pad10 gap-auto";
    const fileAppearance = "flex vertical-center gap-auto";
    const submitAppearance = "flex vertical vertical-center gap10";
    const paypalAppearance = "background-light roundcorners10 pad10";

    return (
        <div className={appearance}>
            {inputFamilyName}
            {familyWarningText}
            {inputSurname}
            {surnameWarningText}
            {InputMiddleName}<br />
            {ageDropdown}
            {ageWarningText}<br />
            {dateLabel}
            <div className={dateAppearance}>
                {dayDropdown}
                {monthDropdown}
                {yearDropdown}
            </div>
            {dateWarningText}<br />
            {conflictDropdown}
            {conflictWarningText}<br />    
            {uploadImageText}  
            <div className={fileAppearance}>
                {uploadImage}{uploadImageName}
            </div><br />
            {uploadAudioText}
            <div className={fileAppearance}>
                {uploadAudio}{uploadAudioName}
            </div><br />
            <div className={reviewAppearance}>
                <div>
                    {reviewCard}
                </div> 
                {reviewText}   
            </div><br />    
            {cookies ? 
                <PayPalScriptProvider options={paypalOptions}>
                    <div className={submitAppearance} >
                        {contributionText}
                        {formWarningText}    
                        {submitButton}
                        {creditcardNotice}
                        <div className={paypalAppearance} style={{display: (canPay ? "inline" : "none"),width: "100%"}}>                            
                            {paymentButton}
                        </div>                        
                    </div>
                </PayPalScriptProvider> : <div className={submitAppearance} >
                    {enableCookiesText}
                    {cookiePolicyButton}
                </div>
            }           
        </div>
    )
}

export default MemoryForm;