export const day = [
    {
        value: "",
        option: "Day?"
    },
    {
        value: "1",
        option: "1"
    },
    {
        value: "2",
        option: "2"
    },
    {
        value: "3",
        option: "3"
    },
    {
        value: "4",
        option: "4"
    },
    {
        value: "5",
        option: "5"
    },
    {
        value: "6",
        option: "6"
    },
    {
        value: "7",
        option: "7"
    },
    {
        value: "8",
        option: "8"
    },
    {
        value: "9",
        option: "9"
    },
    {
        value: "10",
        option: "10"
    },
    {
        value: "11",
        option: "11"
    },
    {
        value: "12",
        option: "12"
    },
    {
        value: "13",
        option: "13"
    },
    {
        value: "14",
        option: "14"
    },
    {
        value: "15",
        option: "15"
    },
    {
        value: "16",
        option: "16"
    },
    {
        value: "17",
        option: "17"
    },
    {
        value: "18",
        option: "18"
    },
    {
        value: "19",
        option: "19"
    },
    {
        value: "20",
        option: "20"
    },
    {
        value: "21",
        option: "21"
    },
    {
        value: "22",
        option: "22"
    },
    {
        value: "23",
        option: "23"
    },
    {
        value: "24",
        option: "24"
    },
    {
        value: "25",
        option: "25"
    },
    {
        value: "26",
        option: "26"
    },
    {
        value: "27",
        option: "27"
    },
    {
        value: "28",
        option: "28"
    },
    {
        value: "29",
        option: "29"
    },
    {
        value: "30",
        option: "30"
    },
    {
        value: "31",
        option: "31"
    },
];

export const month = [
    {
        value: "",
        option: "Month?"
    },
    {
        value: "January",
        option: "January"
    },
    {
        value: "February",
        option: "February"
    },
    {
        value: "March",
        option: "March"
    },
    {
        value: "April",
        option: "April"
    },
    {
        value: "May",
        option: "May"
    },
    {
        value: "June",
        option: "June"
    },
    {
        value: "July",
        option: "July"
    },
    {
        value: "August",
        option: "August"
    },
    {
        value: "September",
        option: "September"
    },
    {
        value: "October",
        option: "October"
    },
    {
        value: "November",
        option: "November"
    },
    {
        value: "December",
        option: "December"
    },
];

export const year = [
    {
        value: "",
        option: "Year?"
    },
    {
        value: "2020",
        option: "2020"
    },
    {
        value: "2021",
        option: "2021"
    },
    {
        value: "2022",
        option: "2022"
    },
    {
        value: "2023",
        option: "2023"
    },
    {
        value: "2024",
        option: "2024"
    },
]