import { useContext } from "react";

import Text from "../elements/Text";
import ButtonLink from "../elements/ButtonLink";

import { useExternalLink } from "../functions/GenericFunctions";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useFooterContent() {
    const {
        changeAppState
    } = useContext(AppStateContext);

    const {
        openNewTab
    } = useExternalLink();

    const siteDesigner = <Text 
        text="©2024, Aphrael Amarantha"
        font="Bold"
        fontcolor="font-white"
        fontsize="0.6rem"
        width="150px"
        visible={true}
    />

    const termsLink = <ButtonLink
        text="Terms & Policies"
        font="Bold"
        fontsize="0.6rem"
        fontcolor="font-white"
        width="150"
        visible={true}
        onClick={() => changeAppState("TERMS")}
    />

    const xLink = <ButtonLink
        text="Contact Us on X"
        font="Bold"
        fontsize="0.6rem"
        fontcolor="font-white"
        width="150"
        visible={true}
        onClick={() => openNewTab("https://twitter.com/LostEchoesOnX")}
    />

    const blueSkyLink = <ButtonLink
        text="Contact Us on BlueSky"
        font="Bold"
        fontsize="0.6rem"
        fontcolor="font-white"
        width="150"
        visible={true}
        onClick={() => openNewTab("https://bsky.app/profile/lostechoesonline.bsky.social")}
    />

    return { siteDesigner, termsLink, xLink, blueSkyLink };
}