import Text from "../elements/Text";
import ImageSpinner from "../elements/ImageSpinner";

import Spinner from "../images/icon-spinner.svg";

export function useLoadingPageContent() {
    const loadingText = <Text 
        text="Please wait. Do not navigate away from this page."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="400px"
        visible={true}
    />

    const spinIcon = <ImageSpinner 
        image={Spinner}
        alt="Loading..."
        height="50px"
        width="50px"
        padding=""
        visible={true}
    />

    return { loadingText, spinIcon };
}