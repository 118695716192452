import { usePrivacyPolicyContent } from "./PrivacyPolicy-Content";

const PrivacyPolicy = ({ privacyRef, cookiesRef }) => {
    const {
        privacyHeader,
        updatedText,
        introHeader,
        introText1,
        introText2,
        infoCollectHeader,
        infoCollectText1,
        infoCollectText2,
        cookiesHeader,
        cookiesText,
        contactHeader,
        contactText
    } = usePrivacyPolicyContent(cookiesRef);

    const appearance = "flex vertical border-top top-pad50 gap10";

    return (
        <div ref={privacyRef} className={appearance}>
            {privacyHeader}
            {updatedText}
            <br />
            {introHeader}
            {introText1}<br />
            {introText2}
            <br />
            {infoCollectHeader}
            {infoCollectText1}<br />
            {infoCollectText2}
            <br />
            {cookiesHeader}
            {cookiesText}
            <br />
            {contactHeader}
            {contactText}
        </div>
    )
}

export default PrivacyPolicy;