import "../stylesheets/dropdown.css";
import { useDropdownContent } from "./Dropdown-Content";
import { useDropdownFunctions } from "../functions/DropdownFunctions";
import { useOutsideClick } from "../functions/GenericFunctions";

const Dropdown = ({ label, labelSize, defaultOption, optionList, width, height, value, select }) => {
    const {
        isOpen,
        toggleDropdown,
        closeDropdown
    } = useDropdownFunctions();

    const {
        labelText,
        downArrow,
        upArrow,
        selectedText,
        options
    } = useDropdownContent({label, labelSize, width, defaultOption, optionList, value, select});

    const appearance = "flex vertical relative gap10";
    const selectedAppearance = "flex vertical-center dropdownBorder pointer pad5 gap-auto"
    const optionWindowAppearance = "flex absolute vertical float dropdownBorder pad5 overflow-y-scroll scroller"

    const clickOutside = useOutsideClick(closeDropdown);

    return (
        <div className={appearance}>
            {labelText}
            <div ref={clickOutside} style={{maxWidth: width}}>
                <div className={selectedAppearance} onClick={toggleDropdown}>
                    {selectedText}{isOpen ? upArrow : downArrow}
                </div> 
                {isOpen && (
                    <div className={optionWindowAppearance} style={{maxWidth: width, maxHeight: height, left: "0px"}} onClick={closeDropdown}>
                        {options}
                    </div>
                )}               
            </div>            
        </div>
    )
}

export default Dropdown;