import { useAppBodyContentNew } from "./AppBody-ContentNew";

const AppBodyNew = ({scrollRef}) => {
    const {
        body,
        header,
        memorypage,
        success,
        failure,
        terms,
        loading,
        footer,
        cookieWarning
    } = useAppBodyContentNew({scrollRef});

    const appearance = "relative vertical vertical-center top-pad10 gap5 wrap";

    const bodyAppearance="overflow-y-scroll scroller";

    const footerAppearance = "flex horizontal-center"

    return (
         <div className={appearance} style={{maxHeight: "100%"}}>
            {header}
            <div ref={scrollRef} className={bodyAppearance} style={{maxHeight: `calc(100% - 270px)`}}>
                {body}
                {memorypage}
                {success}
                {failure}
                {terms}
                {loading}
            </div>            
            <div className={footerAppearance}>
                {footer}
                {cookieWarning}
            </div>            
         </div>
    )
}

export default AppBodyNew;