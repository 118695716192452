import { useMemoryPageContent } from "./MemoryPage-Content";

const MemoryPage = ({ visible }) => {
    const {
        memoryText,
        memoryForm,
        memoryText2,
        memoryText3,
        memoryText4
    } = useMemoryPageContent()

    const appearance = "flex vertical vertical-center gap30";
    const textAppearance = "flex vertical gap20 pad10";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none"), maxWidth: "800px"}}>
            <div className={textAppearance}>
                {memoryText}
                {memoryText2}
                {memoryText3}
                {memoryText4}
            </div>            
            {memoryForm}
        </div>
    )
}

export default MemoryPage;