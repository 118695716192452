import Text from "../elements/Text";

export function useRefundPolicyContent() {
    const header = <Text 
        text="Refund Policy"
        font="SemiBold"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const updatedText = <Text 
        text="Last Updated: 17 March 2024"
        font="MediumItalic"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const policyText = <Text 
        text="A contribution of $5.00(USD) is required to add a memory to this site.
                   This helps cover server costs, processing fees incurred by utilizing payment processing services provided by PayPal, and other additional costs.
                   Be aware that all payments are final. 
                   Refunds will not be provided so please ensure that all of the information you provide is correct to the best of your ability."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    return { header, updatedText, policyText };
}