import "../stylesheets/inputs.css";

const InputRangeSlider = ({ sliderLength, value, sliderRef, onChange }) => {
    const appearance = "bottom-pad8";

    return (
        <div className={appearance}>
            <input 
                type="range"
                min="0"
                max="100"
                value={value}
                ref={sliderRef}
                onChange={(e) => onChange(e)}
                style={{width: sliderLength}}
            />
        </div>
    )
}

export default InputRangeSlider;