import { useContext } from "react";

import { useCookiePolicyContent } from "./CookiePolicy-Content";

import { AppStateContext } from "../contexts/AppStateContexts";

const CookiePolicy = ({ cookieRef }) => {
    const {
        cookies
    } = useContext(AppStateContext);

    const {         
        cookiePolicyHeader, 
        lastUpdatedText,
        introductionHeader,
        introductionText,
        cookieDefinition,
        cookieDefinitionText,
        cookieTypes,
        typeHeader1,
        essentialCookiesText,
        typeHeader2,
        thirdPartyCookiesText,
        cookieSettings,
        cookieSettingsText,
        settingsHeader1,
        cookieConsentText,
        settingsHeader2,
        browserSettingsText,
        settingsHeader3,
        websiteSettingsText,
        changeCookiePolicy,
        changePolicyText,
        contactUsHeader,
        contactUsText,
        additionalInfoHeader,
        additionalInfoText,
        cookieAgreementText,
        acceptButton,
        declineButton
    } = useCookiePolicyContent();

    const appearance = "flex vertical border-top top-pad50 gap10";
    const acceptAppearance = "flex vertical vertical-center gap10"

    return (
        <div ref={cookieRef} className={appearance}>
            {cookiePolicyHeader}
            {lastUpdatedText}
            <br />
            {introductionHeader}
            {introductionText}
            <br />
            {cookieDefinition}
            {cookieDefinitionText}
            <br />
            {cookieTypes}
            {typeHeader1}
            {essentialCookiesText}<br />
            {typeHeader2}
            {thirdPartyCookiesText}
            <br />
            {cookieSettings}
            {cookieSettingsText}
            {settingsHeader1}
            {cookieConsentText}<br />
            {settingsHeader2}
            {browserSettingsText}<br />
            {settingsHeader3}
            {websiteSettingsText}
            <br />
            {changeCookiePolicy}
            {changePolicyText}
            <br />
            {contactUsHeader}
            {contactUsText}
            <br />
            {additionalInfoHeader}
            {additionalInfoText}
            <br />
            <div className={acceptAppearance} style={{display: (cookies ? "none" : "flex")}}>
                {cookieAgreementText}
                <div>
                    {acceptButton}
                </div>                    
            </div>
            <div className={acceptAppearance} style={{display: (cookies ? "flex" : "none")}}>
                <div>
                    {declineButton}
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy;