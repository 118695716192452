const Image = ({image, alt, height, width, padding, visible}) => {
    const padAmount = padding;

    return (
        <div className={padAmount} style={{display: (visible ? "inline" : "none")}}>
            <img className="flex fullcenter top-roundcorners8" src={image} style={{height: height, width: width}} alt={alt} />
        </div>
    )
}

export default Image;