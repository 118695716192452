import { useContext, useEffect, useState } from "react";

import { AppStateContext } from "../contexts/AppStateContexts";

export const onClickTest = () => {
    console.log("Clicked!");
}

export function useTestInputText() {
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    return { textValue: value, onHandleTextInput: handleChange };
}

export function useTestInputPassword() {
    const [value, setValue] = useState("")

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    return { passwordValue: value, onHandlePasswordInput: handleChange };
}

export function useTestInputTextArea() {
    const [value, setValue] = useState();

    const handleChange = (e) => {
        setValue(e.target.value);

        const textarea = e.target;

        textarea.style.height = "auto";

        const areaOffset = 10;
        const areaHeight = textarea.scrollHeight - areaOffset;

        textarea.style.height = `${areaHeight}px`;
    }

    return { textAreaValue: value, onHandleTextAreaInput: handleChange };
}

export function useTestInputCheckbox() {
    const [value, setValue] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.checked);
    }

    return { checkboxValue: value, onHandleCheckboxInput: handleChange }
}

export function useTestInputRangeSlider(audioRef, sliderRef, audiofile) {
    const [value, setValue] = useState(0);

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            if (audiofile) {
                    if (audioElement) {
                    const currentTime = audioElement.currentTime;
                    const duration = audioElement.duration;
                    const newValue = (currentTime / duration) * 100;
                    setValue(newValue);

                    if (sliderRef.current) {                    
                        sliderRef.current.style.background = `linear-gradient(to right, #b9b9b9 ${sliderRef.current.value}%, #000000 ${sliderRef.current.value}%)`;
                    }
                }
            }
        }

        if (audioElement) {
            audioElement.addEventListener("timeupdate", handleTimeUpdate);

            return () => {
                audioElement.removeEventListener("timeupdate", handleTimeUpdate);
            }
        }
    }, [audioRef, sliderRef, audiofile]);

    const handleChange = (e) => {        
        const currentSliderPos = e.target.value;
        const duration = audioRef.current.duration;
        const newTime =  (currentSliderPos * duration) / 100;
        setValue(currentSliderPos);
        audioRef.current.currentTime = newTime;

        const sliderFill = e.target;

        sliderFill.style.background = `linear-gradient(to right, #b9b9b9 ${sliderFill.value}%, #000000 ${sliderFill.value}%)`;
    }

    return { sliderValue: value, onHandleChangeSlider: handleChange }
}

export function useTestScrollToTop(scrollRef) {
    const [isVisible, setIsVisible] = useState(false);

    const {
        canPay
    } = useContext(AppStateContext);

    useEffect(() => {
        const scrollElement = scrollRef?.current;        
        const toggleVisibility = () => {
            const {scrollTop} = scrollElement;
            if (scrollTop > 50) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        scrollElement.addEventListener("scroll", toggleVisibility);

        return () => {
            scrollElement.removeEventListener("scroll", toggleVisibility);
        }
        
    },  [scrollRef]);

    useEffect(() => {
        const scrollElement = scrollRef?.current;
        const heightThreshold = 1710;

        const scrollToBottom = () => {
            if (canPay === true && scrollElement.scrollHeight > heightThreshold) {
                scrollElement.scrollTo({
                     top: 2000,
                     behavior: "smooth"
                 })
             }
        }

        const timeout = setTimeout(scrollToBottom, 200);

        return () => clearTimeout(timeout);        
    }, [scrollRef, canPay]);

    const scrollToTop = () => {
        scrollRef.current.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return { visible: isVisible, scrollToTop };
}

export function useTestScrollToSection() {
    const autoScroll = (ref) => {
        setTimeout(() => ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        }, 200))
    }

    return { autoScroll }
}