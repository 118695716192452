import "../stylesheets/inputs.css";

const InputText = ({name, label, labelfont, labelsize, type, placeholder, value, length, visible, labelvisible, onChange}) => {
    const labelTheme = `${labelfont} flex vertical top-margin5 font-white`;

    return (
        <div className="darkInput" style={{display: (visible ? "inline" : "none")}}>
            <label className={labelTheme} htmlFor={name} style={{fontSize: labelsize, display: (labelvisible ? "flex" : "none")}}>{label}</label>
            <input 
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                style={{width: length}}
                onChange={(e) => onChange(e)}
            />            
        </div>
    )
}

export default InputText;