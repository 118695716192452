import "../stylesheets/buttonText.css";

const ButtonText = ({text, visible, onClick}) => {
    return (
        <div className="btnText" style={{display: (visible ? "inline" : "none")}} onClick={() => onClick()}>
            {text}
        </div>
    )
}

export default ButtonText;