import { useContext } from "react";
import UpArrow from "./images/icon-uparrow.svg";
import ButtonImage from "./elements/ButtonImage";
import AppBodyNew from "./components/AppBody-New";

import { AppStateContext } from "./contexts/AppStateContexts";
import { useTestScrollToTop } from "./functions/TestFunctions";

export function useAppContentNew(scrollRef) {
    const {AppState} = useContext(AppStateContext);

    const {
        theWall,
        memoryForm,
        termsPage
    } = AppState;

    const {
        visible,
        scrollToTop
    } = useTestScrollToTop(scrollRef);

    const appBody = <AppBodyNew scrollRef={scrollRef}/>

    const toTopImage = <ButtonImage         
        image={UpArrow}
        alt="Scroll to top."
        height="30px"
        width="30px"
        padding="0px"
        dynamic={false}
        visible={(theWall || memoryForm || termsPage) ? visible : false}
        onClick={scrollToTop}
    />

    return { appBody, toTopImage }
}