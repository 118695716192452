import "../stylesheets/buttonImage.css";

const ButtonImage = ({image, alt, height, width, padding, visible, dynamic, state, onClick}) => {
    const appearance = `background pointer ${padding} ${state ? "backgroundActive" : ""}`

    return (
        <div className={appearance} style={{minHeight: height, minWidth: width, display: (visible ? "inline" : "none")}} onClick={() => onClick()}>
            <img className={`flex fullcenter ${dynamic ? "image-dynamic" : ""}`} src={image} style={{maxHeight: height, maxWidth: width}} alt={alt}/>
        </div>
    )
}

export default ButtonImage;