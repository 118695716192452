import Image from "../elements/Image";
import Text from "../elements/Text";

import DropdownArrow from "../images/icon-dropdown.svg";
import DropupArrow from "../images/icon-dropup.svg";

export function useDropdownContent({label, labelSize, width, defaultOption, optionList, value, select}) {

    const labelText = <Text 
        text={label}
        font="Medium"
        fontcolor="font-white"
        fontsize={labelSize}
        width="400px"
        visible={true}
    />

    const downArrow = <Image 
        image={DropdownArrow}
        alt="Click for options."
        height="12px"
        width="12px"
        padding=""
        visible={true}
    />

    const upArrow = <Image 
        image={DropupArrow}
        alt="Close dropdown."
        height="12px"
        width="12px"
        padding=""
        visible={true}
    />

    const selectedText = <Text 
        text={value ? value : defaultOption}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width={width}
        visible={true}
    />

    const options = optionList.length > 0 ? optionList.map((opt, key) => 
        <div key={key} className="options pointer" onClick={() => select(opt.option, opt.value)}>
            {opt.option}
        </div>
    ) : null;

    return { labelText, downArrow, upArrow, selectedText, options };
}