import { useTermsPageContent } from "./TermsPage-Content";

const TermsPage = ({ visible }) => {
    const {
        termsHeaderText,
        scrollToRefunds,
        scrollToCookies,
        scrollToTerms,
        scrollToPrivacy,
        cookies,
        refunds,
        terms,
        privacy
    } = useTermsPageContent();

    const appearance = "flex vertical gap10 pad10";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none")}}>
            {termsHeaderText}<br />
            <div>
                {scrollToTerms}
            </div>
            <div>
                {scrollToPrivacy}
            </div>
            <div>
                {scrollToRefunds}
            </div>
            <div>
                {scrollToCookies}
            </div>
            <br /><br />
            {terms}
            <br /><br />
            {privacy}
            <br /><br />
            {refunds}
            <br /><br />
            {cookies}
        </div>
    )
}

export default TermsPage;