import { useRef } from "react";
import { useAudioPlayerContent } from "./AudioPlayer-Content";

const AudioPlayer = ({ audiofile, id, visible }) => {
    const audioRef = useRef(null);

    const {
        playButton,
        audioSlider
    } = useAudioPlayerContent({id, audioRef, audiofile});

    const controlsAppearance = "flex vertical-center gap5";

    return (
        <div style={{display: (visible ? "inline" : "none")}}>     
            {audiofile && (
                 <audio src={audiofile} ref={audioRef} preload="metadata"/>
            )}           
            <div className={controlsAppearance}>
                {playButton}
                {audioSlider}
            </div>                       
        </div>
    )
}

export default AudioPlayer;