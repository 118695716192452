import { useLoadingPageContent } from "./LoadingPage-Content";

const LoadingPage = ({ visible }) => {
    const {
        loadingText,
        spinIcon
    } = useLoadingPageContent();

    const appearance = "flex vertical vertical-center horizontal-center pad10 gap20";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none"), minHeight: `calc(100vh - 300px)`}}>
            {loadingText}
            {spinIcon}
        </div>
    )
}

export default LoadingPage;