import { createContext, useEffect, useReducer, useState } from "react";

export const AudioStateContext = createContext();

export const AudioStateContextProvider = ({ children }) => {
    const [activeAudioID, setActiveAudioID] = useState(null);
    const [currentRef, setCurrentRef] = useState(null);    

    useEffect(() => {
        if (currentRef) {
            const audioElement = currentRef.current;
            audioElement.onended = (e) => {
                setActiveAudioID(null);
            }
        }
    }, [currentRef]);

    const playAudio = (audioID, audioRef) => {
        if (activeAudioID !== audioID) {
            if (activeAudioID !== null) {                
                currentRef.current.pause();
                currentRef.current.currentTime = 0;
            };
        };        
        setActiveAudioID(audioID);        
        setCurrentRef(audioRef);
        audioRef.current.play();        
    }
    
    const stopAudio = (audioID) => {
        if (activeAudioID === audioID){           
            currentRef.current.pause();            
            setActiveAudioID(null);            
        };
    }

    return (
        <AudioStateContext.Provider value={{ activeAudioID, playAudio, stopAudio, setActiveAudioID }}>
            {children}
        </AudioStateContext.Provider>
    )
}


/* -------------------------------------------------- */


export const AppStateContext = createContext();

export const AppStateContextProvider = ({children}) => {
    const [banner, setBanner] = useState(true);
    const [cookies, setCookies] = useState(false);
    const [canPay, setCanPay] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const initialState = {
        theWall: true,
        memoryForm: false,
        successPage: false,
        failurePage: false,
        termsPage: false,
        loadingPage: false
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    function reducer(state, action) {
        let appState;
        switch (action.type) {
            case "WALL":
                appState = {
                    theWall: state.theWall = true,
                    memoryForm: state.memoryForm = false,
                    successPage: state.successPage = false,
                    failurePage: state.failurePage = false,
                    termsPage: state.termsPage = false,
                    loadingPage: state.loadingPage= false
                }
                break;
            case "MEMORY":
                appState = {
                    theWall: state.theWall = false,
                    memoryForm: state.memoryForm = true,
                    successPage: state.successPage = false,
                    failurePage: state.failurePage = false,
                    termsPage: state.termsPage = false,
                    loadingPage: state.loadingPage= false
                }
                break;
            case "SUCCESS":
                appState = {
                    theWall: state.theWall = false,
                    memoryForm: state.memoryForm = false,
                    successPage: state.successPage = true,
                    failurePage: state.failurePage = false,
                    termsPage: state.termsPage = false,
                    loadingPage: state.loadingPage= false
                }
                break;
            case "FAILURE":
                appState = {
                    theWall: state.theWall = false,
                    memoryForm: state.memoryForm = false,
                    successPage: state.successPage = false,
                    failurePage: state.failurePage = true,
                    termsPage: state.termsPage = false,
                    loadingPage: state.loadingPage= false
                }
                break;
            case "TERMS":
                appState = {
                    theWall: state.theWall = false,
                    memoryForm: state.memoryForm = false,
                    successPage: state.successPage = false,
                    failurePage: state.failurePage = false,
                    termsPage: state.termsPage = true,
                    loadingPage: state.loadingPage= false
                }
                break;
            case "LOADING":
                appState = {
                    theWall: state.theWall = false,
                    memoryForm: state.memoryForm = false,
                    successPage: state.successPage = false,
                    failurePage: state.failurePage = false,
                    termsPage: state.termsPage = false,
                    loadingPage: state.loadingPage= true
                }
                break;
            default:
                throw new Error("Unknown action: " + action.type);
        }

        return appState;
    }

    const changeAppState = (action) => {
        dispatch({ type: action });
    }

    const toggleBanner = () => {
        if (banner) {
            setBanner(false);
        } else {
            setBanner(true);
        }
    }

    const toggleCookies = () => {
        if (cookies) {
            setCookies(false);
        } else {
            setCookies(true);
        }
    }

    const handleSearchChange = (e) => {
        const uppercase = e.target.value.toUpperCase();
        setSearchValue(uppercase);
    }

    const toggleCanPay = (toggle) => {
        setCanPay(toggle);
    }

    return (
        <AppStateContext.Provider value={{ AppState: state, banner, cookies, canPay, searchValue, changeAppState, toggleBanner, toggleCookies, handleSearchChange, toggleCanPay }}>
            {children}
        </AppStateContext.Provider>
    )
}