import { useContext } from "react";

import { AppStateContext } from "../contexts/AppStateContexts";

import { useHeaderContent } from "./Header-Content";

const Header = () => {
    const {AppState} = useContext(AppStateContext);

    const {
        theWall,
        memoryForm,
        termsPage
    } = AppState;

    const {
        logo,
        purposeText,
        addButton,
        searchField,
        backButton,
        backText
    } = useHeaderContent();

    const appearance = "flex vertical vertical-center gap10 bottom-pad10";
    const subBarAppearance = "flex vertical-center border-top gap10"
    const backBarAppearance = "flex horizontal-center border-top pad10 gap10"

    return (
        <div className={appearance}>
            {logo}
            {purposeText}
            <div className={subBarAppearance} style={{display: (theWall ? "flex" : "none")}}>
                {searchField}
                <div>
                    {addButton}
                </div>              
            </div>
            <div className={backBarAppearance} style={{display: (memoryForm ? "flex" : "none"), minWidth: `calc(100% - 30px)`}}>
                {backButton}
                {backText}
            </div>
            <div className={backBarAppearance} style={{display: (termsPage ? "flex" : "none"), minWidth: `calc(100% - 30px)`}}>
                {backButton}
                {backText}
            </div>
        </div>
    )
}

export default Header;