import { useCardsContainerContent } from "./CardsContainer-Content";

const CardsContainer = ({visible, scrollRef}) => {
    const {        
        displayCards
    } = useCardsContainerContent({scrollRef});

    const appearance = "flex horizontal-center top-pad10 bottom-pad10 gap5 wrap";

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none")}}>
            {displayCards}
        </div>
    )
}

export default CardsContainer;