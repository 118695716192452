import { useRefundPolicyContent } from "./RefundPolicy-Content";

const RefundPolicy = ({ refundRef }) => {
    const {
        header,
        updatedText,
        policyText
    } = useRefundPolicyContent();

    const appearance = "flex vertical border-top top-pad50 gap10";

    return (
        <div ref={refundRef} className={appearance}>
            {header}
            {updatedText}
            <br />
            {policyText}
        </div>
    )
}

export default RefundPolicy;