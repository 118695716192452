import { useContext, useRef } from "react";

import ButtonImage from "../elements/ButtonImage";
import InputRangeSlider from "../elements/InputRangeSlider";

import { AudioStateContext } from "../contexts/AppStateContexts";

import { useTestInputRangeSlider } from "../functions/TestFunctions";

import PlayButtonIcon from "../images/icon-play.svg";
import PauseButtonIcon from "../images/icon-pause.svg";

export function useAudioPlayerContent( {id, audioRef, audiofile} ) {
    const sliderRef = useRef(null);

    const {activeAudioID, playAudio, stopAudio} = useContext(AudioStateContext);

    const {
        sliderValue,
        onHandleChangeSlider
    } = useTestInputRangeSlider(audioRef, sliderRef, audiofile);

    const playButton = <ButtonImage         
        image={((activeAudioID === id) ? PauseButtonIcon : PlayButtonIcon)}
        alt="Play Audio"
        height="15px"
        width="15px"
        padding="0px"
        dynamic={false}
        visible={true}
        onClick={() => (activeAudioID === id) ? stopAudio(id) : playAudio(id, audioRef)}
    />

    const audioSlider = <InputRangeSlider 
        sliderLength="168px"
        value={sliderValue}
        sliderRef={sliderRef}
        onChange={onHandleChangeSlider}
    />

    return { playButton, audioSlider }
}