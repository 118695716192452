import AudioPlayer from "./AudioPlayer";
import Image from "../elements/Image";
import Text from "../elements/Text";

import DefaultImg from "../images/icon-defaultImage.svg";

export function useRememberenceCard( {hasImage, victimImg, familyName, surname, middlename, age, conflict, date, audio, cardID} ) {
    const defaultImage = <Image 
        image={hasImage ? victimImg : DefaultImg}
        alt="Default Image"
        height="200px"
        width="200px"
        padding=""
        visible={true}
    />

    const familyNameText = <Text 
        text={familyName}
        font="Regular"
        fontcolor="font-white"
        fontsize="1.5rem"
        width="200px"
        visible={true}
    />

    const surnameText = <Text 
        text={`${surname} ${middlename ? middlename : ""}`}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="200px"
        visible={true}
    />

    const ageText = <Text 
        text="Age"
        font="Italic"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="50px"
        visible={true}
    />

    const ageNumberText = <Text 
        text={age}
        font="Regular"
        fontcolor="font-white"
        fontsize="1.5rem"
        width="50px"
        visible={true}
    />

    const conflictTitleText = <Text 
        text="Conflict"
        font="Italic"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="130px"
        visible={true}
    />

    const conflictText = <Text 
        text={conflict}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="130px"
        visible={true}
    />

    const deceasedText = <Text 
        text="Deceased"
        font="Italic"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="200px"
        visible={true}
    />

    const dateText = <Text 
        text={date}
        font="Regular"
        fontcolor="font-white"
        fontsize="1.5rem"
        width="200px"
        visible={true}
    />

    const memoryText = <Text 
        text="Listen to a memory..."
        font="Italic"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="200px"
        visible={true}
    />

    const audioplayer = <AudioPlayer 
        audiofile={audio}
        id={cardID}
        visible={true}
    />

    return { defaultImage,  familyNameText, surnameText, ageText, ageNumberText, conflictTitleText, conflictText, deceasedText, dateText, memoryText, audioplayer};
}