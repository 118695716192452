import Text from "../elements/Text";
import ButtonLink from "../elements/ButtonLink";

import { useExternalLink } from "../functions/GenericFunctions";
import { useTestScrollToSection } from "../functions/TestFunctions";

export function usePrivacyPolicyContent( cookiesRef ) {
    const {
        openNewTab
    } = useExternalLink()

    const {
        autoScroll
    } = useTestScrollToSection();

    const privacyHeader = <Text 
        text="Privacy Statement"
        font="SemiBold"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const updatedText = <Text 
        text="Last Updated: 24 April 2024"
        font="MediumItalic"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const introHeader = <Text 
        text="Introduction"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const introText1 = <Text 
        text="This Privacy Statement applies to all actions to all users on Lost Echoes Online ('we, us, the Platform').
                    It is aimed to provide information to you ('the user, users') regarding how your personal information is collected and used when you visit the Platform and use our services.
                    All users are encouraged to thoroughly read this Privacy Statement and to use it to assist you to make an informed decision."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const introText2 = <Text 
        text="Be aware that we may occassionally make changes to this Privacy Statement in accordance to changes in law, environmental factors, updated business needs, services and other possible changes.
                   Any changes to this Privacy Statement will be effective per the published last updated date."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const infoCollectHeader = <Text 
        text="Personal Information We Collect"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const paypalLink = <ButtonLink
        text="PayPal Privacy Statement"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://www.paypal.com/us/legalhub/privacy-full")} 
    />

    const infoCollectText1 = <Text 
        text="We do not collect personal information from any users who visit the Platform.
                   The information we do collect is information provided to us with consent in regards to victims of war and/or combat zones who have passed.
                   This information is then publicly displayed on the front page of this website as a memorial to all of the innocent lives lost.
                   The information collected are the victims' names, the age they passed, what conflict they were a victim of, the date they passed, an image of their likeness if available, and a short audio clip of their voice if available."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const infoCollectText2 = <Text 
        text={<>We use PayPal for payments and other services. 
            If you wish to use one of these services and pay on our website, PayPal may collect the personal data you provide, such as payment and other identifying information. 
            PayPal uses this information to operate and improve the services it provides to us and others, including for fraud detection, harm and loss prevention, authentication, analytics related to the performance of its services, and to comply with applicable legal requirements. 
            The processing of this information will be subject to the {paypalLink} available at PayPal.com.</>}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookiesHeader = <Text 
        text="Third-Party Cookies"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const cookieLink = <ButtonLink
        text="Cookie Policy"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => autoScroll(cookiesRef)}
    />

    const cookiesText = <Text 
        text={<>The Platform requires the use of third-party cookies for processing financial requests with PayPal.
            To make a contribution, you will need to consent to the use of third-party cookies on the Platform for the purpose of using your personal financial data to process a payment via PayPal.
            For more information, please read our {cookieLink}.</>}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const contactHeader = <Text 
        text="Contact Us"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const xLink = <ButtonLink
        text="x.com/LostEchoesOnX"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://twitter.com/LostEchoesOnX")}
    />

    const blueskyLink = <ButtonLink
        text="LostEchoesOnline.bsky.social"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://bsky.app/profile/lostechoesonline.bsky.social")}
    />

    const contactText = <Text 
        text={<>For questions regarding this Privacy Statement, please contact us directly on X at {xLink} or on BlueSky at {blueskyLink}.</>}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    return {
        privacyHeader,
        updatedText,
        introHeader,
        introText1,
        introText2,
        infoCollectHeader,
        infoCollectText1,
        infoCollectText2,
        cookiesHeader,
        cookiesText,
        contactHeader,
        contactText
    }
}