import Text from "../elements/Text";

import RememberenceCard from "../components/RememberenceCard";

import { useCardsFunctions } from "../functions/CardsFunctions";

export function useCardsContainerContent({scrollRef}) {
    const {
        cards
    } = useCardsFunctions(scrollRef);

    const emptyText = <Text 
        text="No names/memories currently available."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="700px"
        visible={true}
    />

    const emptyCards = 
        <div className="flex vertical vertical-center horizontal-center pad20" style={{minHeight: `calc(100vh - 300px)`}}>
            {emptyText}
        </div>

    const displayCards = cards.length > 0 ? cards.map((item, key) => 
        <RememberenceCard 
            key={key}
            hasImage={item.has_image}
            victimImg={item.victim_img}
            familyName={item.family_name}
            surname={item.surname}
            middlename={item.middle_name}
            age={item.age}
            conflict={item.conflict}
            hasAudio={item.has_audio}
            audio={item.audio}
            date={`${item.day} ${item.month} ${item.year}`}
            cardID={item.id}
        />
    ) : emptyCards;

    return { displayCards, emptyCards };
}