import { useContext } from "react";

import Text from "../elements/Text";
import ButtonText from "../elements/ButtonText";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useSuccessPageContent() {
    const {
        changeAppState
    } = useContext(AppStateContext);

    const successHeader = <Text 
        text="Thank You!"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.8rem"
        width="400px"
        visible={true}
    />

    const successText = <Text 
        text="Their memory has been preserved. 
                    They can be viewed upon the wall. 
                    Please spread the word and let others know that they too can honor the memories of those unfairly lost."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="800px"
        visible={true}
    />

    const returnButton = <ButtonText 
        text="Finished!"
        visible={true}
        onClick={() => changeAppState("WALL")}
    />

    return { successHeader, successText, returnButton };
}