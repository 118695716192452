import { useRememberenceCard } from "./RememberenceCard-Content";

const RememberenceCard = ({hasImage, victimImg, familyName, surname, middlename, age, conflict, hasAudio, audio, date, cardID}) => {
    const {
        defaultImage,
        familyNameText,
        surnameText,
        ageText,
        ageNumberText,
        conflictTitleText,
        conflictText,
        deceasedText,
        dateText,
        memoryText,
        audioplayer
    } = useRememberenceCard({hasImage, victimImg, familyName, surname, middlename, age, conflict, audio, date, cardID});

    const cardAppearance = "flex vertical card gap10 pad5";

    const bodyApperance = "flex vertical pad5 border-top wrap";

    const subAppearance = "flex gap10 border-top";

    const categoryAppearance = "flex vertical pad5 wrap"

    return (
        <div className={cardAppearance}>
            {defaultImage}
            <div className={categoryAppearance}>
                {familyNameText}
                {surnameText}
            </div>
            <div className={subAppearance}>
                <div className={categoryAppearance}>
                    {ageText}
                    {ageNumberText}
                </div>
                <div className={categoryAppearance}>
                    {conflictTitleText}
                    {conflictText}
                </div>
            </div>
            <div className={bodyApperance}>
                {deceasedText}
                {dateText}
            </div>
            <div className={bodyApperance} style={{display: (hasAudio ? "inline" : "none")}}>
                {memoryText}
                {audioplayer}              
            </div>                   
        </div>
    )
}

export default RememberenceCard;