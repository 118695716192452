import { useState } from "react";

export function useDropdownFunctions() {    
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        if (isOpen) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }    

    const closeDropdown = () => {
        setIsOpen(false);
    }

    return { isOpen, toggleDropdown, closeDropdown };
}

export function useDropDownSelections() {
    const [selectedValue, setSelectedValue] = useState("");

    const handleSelect = (option) => {        
        setSelectedValue(option);        
    }

    return { selectedValue, handleSelect };
}