import { useContext } from "react";
import CardsContainer from "./CardsContainer";
import Header from "./Header";
import MemoryPage from "./MemoryPage";
import SuccessPage from "./SuccessPage";
import FailurePage from "./FailurePage";
import TermsPage from "./TermsPage";
import LoadingPage from "./LoadingPage";
import Footer from "./Footer";
import CookieBanner from "./CookieBanner";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useAppBodyContentNew({scrollRef}) {
    const {
        AppState,
        banner
    } = useContext(AppStateContext);

    const {
        theWall,
        memoryForm,
        successPage,
        failurePage,
        termsPage,
        loadingPage
    } =  AppState;

    const header = <Header />

    const body = <CardsContainer 
        visible={theWall}
        scrollRef={scrollRef}
    />
    
    const memorypage = <MemoryPage visible={memoryForm}/>   
    const success = <SuccessPage visible={successPage} />
    const failure = <FailurePage visible={failurePage} />
    const terms = <TermsPage visible={termsPage} />
    const loading = <LoadingPage visible={loadingPage} />
    const footer = <Footer visible={loadingPage ? false : true} />
    const cookieWarning = <CookieBanner visible={banner} />

    return { body, header, memorypage, success, failure, terms, loading, footer, cookieWarning };
}