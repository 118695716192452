const ageList = [
    {
        value: "0",
        option: "0"
    },
    {
        value: "1",
        option: "1"
    },
    {
        value: "2",
        option: "2"
    },
    {
        value: "3",
        option: "3"
    },
    {
        value: "4",
        option: "4"
    },
    {
        value: "5",
        option: "5"
    },
    {
        value: "6",
        option: "6"
    },
    {
        value: "7",
        option: "7"
    },
    {
        value: "8",
        option: "8"
    },
    {
        value: "9",
        option: "9"
    },
    {
        value: "10",
        option: "10"
    },
    {
        value: "11",
        option: "11"
    },
    {
        value: "12",
        option: "12"
    },
    {
        value: "13",
        option: "13"
    },
    {
        value: "14",
        option: "14"
    },
    {
        value: "15",
        option: "15"
    },
    {
        value: "16",
        option: "16"
    },
    {
        value: "17",
        option: "17"
    },
    {
        value: "18",
        option: "18"
    },
    {
        value: "19",
        option: "19"
    },
    {
        value: "20",
        option: "20"
    },
    {
        value: "21",
        option: "21"
    },
    {
        value: "22",
        option: "22"
    },
    {
        value: "23",
        option: "23"
    },
    {
        value: "24",
        option: "24"
    },
    {
        value: "25",
        option: "25"
    },
    {
        value: "26",
        option: "26"
    },
    {
        value: "27",
        option: "27"
    },
    {
        value: "28",
        option: "28"
    },
    {
        value: "29",
        option: "29"
    },
    {
        value: "30",
        option: "30"
    },
    {
        value: "31",
        option: "31"
    },
    {
        value: "32",
        option: "32"
    },
    {
        value: "33",
        option: "33"
    },
    {
        value: "34",
        option: "34"
    },
    {
        value: "35",
        option: "35"
    },
    {
        value: "36",
        option: "36"
    },
    {
        value: "37",
        option: "37"
    },
    {
        value: "38",
        option: "38"
    },
    {
        value: "39",
        option: "39"
    },
    {
        value: "40",
        option: "40"
    },
    {
        value: "41",
        option: "41"
    },
    {
        value: "42",
        option: "42"
    },
    {
        value: "43",
        option: "43"
    },
    {
        value: "44",
        option: "44"
    },
    {
        value: "55",
        option: "55"
    },
    {
        value: "56",
        option: "56"
    },
    {
        value: "57",
        option: "57"
    },
    {
        value: "58",
        option: "58"
    },
    {
        value: "59",
        option: "59"
    },
    {
        value: "60",
        option: "60"
    },
    {
        value: "61",
        option: "61"
    },
    {
        value: "62",
        option: "62"
    },
    {
        value: "63",
        option: "63"
    },
    {
        value: "64",
        option: "64"
    },
    {
        value: "65",
        option: "65"
    },
    {
        value: "66",
        option: "66"
    },
    {
        value: "67",
        option: "67"
    },
    {
        value: "68",
        option: "68"
    },
    {
        value: "69",
        option: "69"
    },
    {
        value: "70",
        option: "70"
    },
    {
        value: "71",
        option: "71"
    },
    {
        value: "72",
        option: "72"
    },
    {
        value: "73",
        option: "73"
    },
    {
        value: "74",
        option: "74"
    },
    {
        value: "75",
        option: "75"
    },
    {
        value: "76",
        option: "76"
    },
    {
        value: "77",
        option: "77"
    },
    {
        value: "78",
        option: "78"
    },
    {
        value: "79",
        option: "79"
    },
    {
        value: "80",
        option: "80"
    },
    {
        value: "81",
        option: "81"
    },
    {
        value: "82",
        option: "82"
    },
    {
        value: "83",
        option: "83"
    },
    {
        value: "84",
        option: "84"
    },
    {
        value: "85",
        option: "85"
    },
    {
        value: "86",
        option: "86"
    },
    {
        value: "87",
        option: "87"
    },
    {
        value: "88",
        option: "88"
    },
    {
        value: "89",
        option: "89"
    },
    {
        value: "90",
        option: "90"
    },{
        value: "91",
        option: "91"
    },
    {
        value: "92",
        option: "92"
    },
    {
        value: "93",
        option: "93"
    },
    {
        value: "94",
        option: "94"
    },
    {
        value: "95",
        option: "95"
    },
    {
        value: "96",
        option: "96"
    },
    {
        value: "97",
        option: "97"
    },
    {
        value: "98",
        option: "98"
    },
    {
        value: "99",
        option: "99"
    },
    {
        value: "100",
        option: "100"
    },
    {
        value: "101",
        option: "101"
    },
    {
        value: "102",
        option: "102"
    },
    {
        value: "103",
        option: "103"
    },
    {
        value: "104",
        option: "104"
    },
    {
        value: "105",
        option: "105"
    },
    {
        value: "106",
        option: "106"
    },
    {
        value: "107",
        option: "107"
    },
    {
        value: "108",
        option: "108"
    },
    {
        value: "109",
        option: "109"
    },
    {
        value: "110",
        option: "110"
    },
    {
        value: "111",
        option: "111"
    },
    {
        value: "112",
        option: "112"
    },
    {
        value: "113",
        option: "113"
    },
    {
        value: "114",
        option: "114"
    },
    {
        value: "115",
        option: "115"
    },
    {
        value: "116",
        option: "116"
    },
    {
        value: "117",
        option: "117"
    },
    {
        value: "118",
        option: "118"
    },
    {
        value: "119",
        option: "119"
    },
    {
        value: "120",
        option: "120"
    },
]

export default ageList;