import { usePayPalButtonContent } from "./PayPalButton-Content";

const PayPalButton = ({ onClick, createOrder, onApprove, onError, visible }) => {
    const {
        paypalButton
    } = usePayPalButtonContent({ onClick, createOrder, onApprove, onError })    

    return (        
        <div style={{display: (visible ? "inline" : "none")}}>
            {paypalButton}
        </div>
    )
}

export default PayPalButton;