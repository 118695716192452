import { useCookieBannerContent } from "./CookieBanner-Content";

const CookieBanner = ({ visible }) => {
    const {
        bannerHeader,
        bannerText,
        acceptButton,
        declineButton,
        policyButton
    } = useCookieBannerContent();

    const appearance = "flex absolute background-verydark float gap-auto pad10";
    const textAppearance = "flex vertical gap5";
    const buttonAppearance = "flex vertical vertical-center horizontal-center gap20 pad10"

    return (
        <div className={appearance} style={{display: (visible ? "flex" : "none"), maxWidth: "100%", bottom: "0px"}}>
            <div className={textAppearance}>
                {bannerHeader}
                {bannerText}
            </div>
            <div className={buttonAppearance}>
                <div>
                    {acceptButton}                    
                </div>
                <div>
                    {declineButton}                    
                </div>
                <div>
                    {policyButton}
                </div>
            </div>            
        </div>
    )
}

export default CookieBanner;