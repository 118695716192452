import { useFailurePageContent } from "./FailurePage-Content";

const FailurePage = ({ visible }) => {
    const {
        failureHeader,
        failuerText,
        returnButton,
        xLink,
        blueskyLink,
        xText,
        blueskyText
    } = useFailurePageContent();

    const appearance = "flex vertical vertical-center horizontal-center pad10 gap10";
    const linkAppearance = "flex horizontal-center vertical-center gap20 wrap";
    const singleAppearance = "flex vertical vertical-center gap5"

    return (
        <div className={appearance} style={{display: (visible? "flex" : "none"), minHeight: `calc(100vh - 300px)`}}>
            {failureHeader}
            {failuerText}
            <br />
            <div className={linkAppearance}>
                <div className={singleAppearance}>
                    {xText}
                    {xLink}
                </div>
                <div className={singleAppearance}>
                    {blueskyText}
                    {blueskyLink}
                </div>
            </div><br />
            {returnButton}            
        </div>
    )
}

export default FailurePage;