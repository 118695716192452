import { useContext } from "react";
import Text from "../elements/Text";
import ButtonText from "../elements/ButtonText";
import ButtonLink from "../elements/ButtonLink";

import { AppStateContext } from "../contexts/AppStateContexts";

import { useExternalLink } from "../functions/GenericFunctions";

export function useFailurePageContent() {
    const {changeAppState} = useContext(AppStateContext);

    const {
        openNewTab
    } = useExternalLink();

    const failureHeader = <Text 
        text="Uh Oh!"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.8rem"
        width="400px"
        visible={true}
    />

    const failuerText = <Text 
        text="Something went wrong! 
                    Please record the time you attempted to upload a memory.
                    Then contact support on our X or BlueSky social media accounts for further assistance. 
                    We apologize for the inconvenience."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.2rem"
        width="800px"
        visible={true}
    />

    const returnButton = <ButtonText 
        text="Return"
        visible={true}
        onClick={() => changeAppState("MEMORY")}
    />

    const xText = <Text 
        text="Go to X"
        font="Regular"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="250px"
        visible={true}
    />

    const blueskyText = <Text 
        text="Go to BlueSky"
        font="Regular"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="250px"
        visible={true}
    />

    const xLink = <ButtonLink
        text="x.com/LostEchoesOnX"
        font="SemiBold"
        fontsize="1.2rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://twitter.com/LostEchoesOnX")}
      />

      const blueskyLink = <ButtonLink
        text="LostEchoesOnline.bsky.social"
        font="SemiBold"
        fontsize="1.2rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://bsky.app/profile/lostechoesonline.bsky.social")}
      />

    return { failureHeader, failuerText, returnButton, xLink, blueskyLink, xText, blueskyText };
}