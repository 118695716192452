const Text = ({text, font, fontcolor, fontsize, width, visible}) => {

    const textStyle = `${font} ${fontcolor}`;

    return (
        <div style={{display: (visible ? "inline" : "none"), maxWidth: width}}>
            <span className={textStyle} style={{fontSize: fontsize}}>{text}</span>
        </div>
    )
}

export default Text;