import { collection, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from "firebase/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { db } from "../Firebase";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useCardsFunctions(scrollRef) {
    const [cards, setCards] = useState([]);
    const [lastCard, setLastCard] = useState(null);
    const [isLoadingBatch, setIsLoadingBatch] = useState(false);

    const {
        searchValue,
        AppState
    } = useContext(AppStateContext);

    const {
        theWall
    } = AppState;

    const maxMemoriesBatchSize = 64;

    const LoadCardBatch = useCallback( async () => {
        //console.log(searchValue);
        if (isLoadingBatch || !theWall) return;
        setIsLoadingBatch(true);

        if (searchValue === "") {
            try {            
                const cardsRef = collection(db, "memories");
                const q = query(cardsRef, orderBy("family_name"), startAfter(lastCard), limit(maxMemoriesBatchSize));
                const snapshot = await getDocs(q);
                const docData = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    
                setCards((prevCards) => [...prevCards, ...docData]);
    
                if (snapshot.docs.length > 0) {
                    const endCard = snapshot.docs[snapshot.docs.length - 1];
                    setLastCard(endCard);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingBatch(false);
                //console.log("Load more data!")
            }
        } else {
            try {            
                const cardsRef = collection(db, "memories");
                const q = query(cardsRef, where("family_name", ">=", searchValue), where("family_name", "<=", searchValue + "\uf8ff"), orderBy("family_name"), startAfter(lastCard), limit(maxMemoriesBatchSize));
                const snapshot = await getDocs(q);
                const docData = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    
                setCards((prevCards) => [...prevCards, ...docData]);
    
                if (snapshot.docs.length > 0) {
                    const endCard = snapshot.docs[snapshot.docs.length - 1];
                    setLastCard(endCard);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingBatch(false);
                //console.log("Load filtered data!")
            }            
        }
        
    }, [lastCard, isLoadingBatch, theWall, searchValue]);

    /* Load batch of cards on scroll. */
    useEffect(() => {
        const scrollDiv = scrollRef.current;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;
            if (scrollPercent >= 90) {                
                LoadCardBatch();
            }
        }

        scrollDiv.addEventListener("scroll", handleScroll);

        return () => {
            scrollDiv.removeEventListener("scroll", handleScroll);
        }
    }, [scrollRef, lastCard, LoadCardBatch]);

    /* Load rememberence card data. */
    useEffect(() => {
        let listenRef = () => {};

        const getCards = () => {
            if (searchValue === "") {
                //console.log("running...");
                const cardsRef = collection(db, "memories");
                const q = query(cardsRef, orderBy("family_name"), limit(maxMemoriesBatchSize));
                listenRef = onSnapshot(q, snapshot => {
                    const cardData = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));

                    setCards(cardData);

                    const endCard = snapshot.docs[snapshot.docs.length - 1];
                    setLastCard(endCard);                
                })
            }       
        }

        getCards();

        return () => listenRef();
    }, [searchValue]);

    /* Load queried rememberence card data. */
    useEffect(() => {
        const handler = setTimeout(async() => {
            if (searchValue !=="") {                

                try {                    
                    const cardsRef = collection(db, "memories");
                    const q = query(cardsRef, where("family_name", ">=", searchValue), where("family_name", "<=", searchValue + "\uf8ff"), orderBy("family_name"), limit(maxMemoriesBatchSize));
                    const snapshot = await getDocs(q);
                    const docData = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        
                    setCards(docData);
        
                    const endCard = snapshot.docs[snapshot.docs.length - 1];
                    setLastCard(endCard);
                } catch (error) {
                    console.error(error);
                }
            }            
        }, 500);

        return () => {
            clearTimeout(handler);
        }
    }, [searchValue])

    return { cards };
}