import { useContext, useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../Firebase";

import { AppStateContext } from "../contexts/AppStateContexts";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export function useMemoryFormFunctions() {
    const {
        canPay,
        changeAppState,
        toggleCanPay
    } = useContext(AppStateContext);

    const [familyName, setFamilyName] = useState("");
    const [surname, setSurname] = useState("");
    const [middleName, setMiddleName] = useState("");

    const [age, setAge] = useState("0");
    const [ageValue, setAgeValue] = useState("0")
    const [day, setDay] = useState("");
    const [dayValue, setDayValue] = useState("");
    const [month, setMonth] = useState("");
    const [monthValue, setMonthValue] = useState("");
    const [year, setYear] = useState("");
    const [yearValue, setYearValue] = useState("");

    const [conflict, setConflict] = useState("");
    const [conflictValue, setConflictValue] = useState("");

    const [memoryImg, setMemoryImg] = useState(null);
    const [memoryImgURL, setMemoryImgURL] = useState(null);
    const [imgName, setImgName] = useState("");
    const imageFileTypes = /\.(png|jpg|jpeg)$/i;

    const [audio, setAudio] = useState(null);
    const [audioURL, setAudioURL] = useState(null);
    const [audioName, setAudioName] = useState("");
    const audioFileTypes = /\.(mp3)$/i;

    const [familyNameWarning, setFamilyNameWarning] = useState(false);
    const [surnameWarning, setSurnameWarning] = useState(false);
    const [ageWarning, setAgeWarning] = useState(false);
    const [dateWarning, setDateWarning] = useState(false);
    const [conflictWarning, setConflictWarning] = useState(false);

    const [imgWarning, setImgWarning] = useState(false);
    const [audioWarning, setAudioWarning] = useState(false);

    const [formWarning, setFormWarning] = useState(false);    
    const [isPaid, setIsPaid] = useState(false);

    const reset = () => {
        setFamilyName("");
        setSurname("");
        setMiddleName("");
        setAge("0");
        setAgeValue("0");
        setDay("");
        setDayValue("");
        setMonth("");
        setMonthValue("");
        setYear("");
        setYearValue("");
        setConflict("")
        setConflictValue("");
        setMemoryImg(null);
        setMemoryImgURL(null);
        setImgName("");
        setAudio(null);
        setAudioURL(null);
        setAudioName("");
    }

    useEffect(() => {
        if (familyName === "" || surname === "" || ageValue === "0" || dayValue === "" || monthValue === "" || yearValue === "" || conflictValue === "") {            
            toggleCanPay(false);            
        }      
        
    }, [familyName, surname, ageValue, dayValue, monthValue, yearValue, conflictValue, toggleCanPay]);

    useEffect(() => {
        const uploadToFirestore = async () => {
            if (isPaid) {                
                let imageAddressURL;
                let audioAddressURL;

                const uploadData = async () => {
                    const dataPath = collection(db, "memories");
                    const formData = {
                        has_image: memoryImg ? true : false,
                        victim_img: memoryImg ? imageAddressURL : "",
                        family_name: familyName,
                        surname: surname,
                        middle_name: middleName,
                        age: ageValue,
                        conflict: conflictValue,
                        has_audio: audio ? true : false,
                        audio: audio ? audioAddressURL : "",
                        day: dayValue,
                        month: monthValue,
                        year: yearValue,    
                        timestamp: serverTimestamp()
                    }
        
                    //console.log("Add data:", formData);
                    try {
                        await addDoc(dataPath, formData);
        
                        //console.log("Firestore document id: ", docRef.id);        
        
                        changeAppState("SUCCESS");
                        setIsPaid(false);
                        reset();

                        /*console.log("Success!", {
                            "Family Name": familyName,
                            "Surname": surname,
                            "Middle Name": middleName,
                            "Age": ageValue,
                            "Death": `${dayValue} ${monthValue} ${yearValue}`,
                            "Conflict": conflictValue,
                            "Image File": memoryImg?.name,
                            "Audio File": audio?.name,
                        });*/
                    } catch (error) {
                        changeAppState("FAILURE");
                        setIsPaid(false);
                        console.log(error);
                    }
                }

                if (memoryImg && audio === null) {
                    const fileDate = new Date().toISOString().slice(0, 19);
                    const fileRef = ref(storage, fileDate + imgName);

                    const uploadTask = uploadBytesResumable(fileRef, memoryImg);
                    uploadTask.on("state_changed",
                        (snapshot) => {
                            //console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.error("image upload error: ", error);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then(((downloadURL) => {
                                imageAddressURL = downloadURL;
                                uploadData();
                                //console.log(downloadURL);
                            }))
                        }
                    )
                } else if (memoryImg === null && audio) {
                    const audioDate = new Date().toISOString().slice(0, 19);
                    const audioRef = ref(storage, audioDate + audioName);

                    const uploadTask = uploadBytesResumable(audioRef, audio);
                    uploadTask.on("state_changed", 
                        (snapshot) => {
                            //console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.error("Audio upload error: ", error);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then(((downloadURL) => {
                                audioAddressURL = downloadURL;
                                uploadData();
                                //console.log(downloadURL);
                            }))
                        }
                    )
                } else if (memoryImg && audio) {                    
                    const imageDate = new Date().toISOString().slice(0, 19);
                    const imageRef = ref(storage, imageDate + imgName);
                    const audioDate = new Date().toISOString().slice(0, 19);
                    const audioRef = ref(storage, audioDate + audioName);

                    const uploadTask1 = uploadBytesResumable(imageRef, memoryImg);
                    const uploadTask2 = uploadBytesResumable(audioRef, audio);

                    uploadTask1.on("state_changed",
                        (snapshot) => {
                            //console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.log("Image upload error: ", error);
                        },
                    );

                    await uploadTask1;
                    imageAddressURL = await getDownloadURL(imageRef);
                    //console.log("Image URL: ", imageAddressURL);

                    uploadTask2.on("state_changed",
                        (snapshot) => {
                            //console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        },
                        (error) => {
                            console.log("Audio upload error: ", error);
                        },
                    )

                    await uploadTask2;
                    audioAddressURL = await getDownloadURL(audioRef);
                    console.log("Audio URL: ", audioAddressURL);

                    uploadData();
                } else {
                    uploadData();
                }                
            } else return;
        }

        uploadToFirestore();
    }, [isPaid, familyName, surname, middleName, ageValue, dayValue, monthValue, yearValue, conflictValue, memoryImg, audio, changeAppState, imgName, audioName]);

    const handleFamilyName = (e) => {
        const uppercase = e.target.value.toUpperCase();
        setFamilyName(uppercase);
    }

    const handleSurname = (e) => {
        setSurname(e.target.value);
    }

    const handleMiddleName = (e) => {
        setMiddleName(e.target.value);
    }

    const handleSetAge = (option, value) => {
        setAge(option);
        setAgeValue(value);
    }

    const handleSetDay = (option, value) => {
        setDay(option);
        setDayValue(value);
    }

    const handleSetMonth = (option, value) => {
        setMonth(option);
        setMonthValue(value);
    }

    const handleSetYear = (option, value) => {
        setYear(option);
        setYearValue(value);
    }

    const handleSetConflict = (option, value) => {
        setConflict(option);
        setConflictValue(value);
    }

    const handleSetMemoryImage = (e) => {
        const img = e.target.files?.[0];
        const size = 4194304;
        if (img) {
            if (imageFileTypes.test(img.name)) {
                if (img.size > size) {
                    setMemoryImg(null);
                    setMemoryImgURL(null);
                    setImgWarning(true);
                    setImgName("Please upload an image that is less than 4MB in size.");
                } else {
                    setMemoryImg(e.target.files?.[0]);
                    const imgURL = URL.createObjectURL(img);
                    setMemoryImgURL(imgURL);
                    setImgName(img.name);
                    setImgWarning(false);
                }                
            } else {
                setMemoryImg(null);
                setMemoryImgURL(null);
                setImgWarning(true);
                setImgName("Please upload an image of the correct file type.");
            }            
        } else {
            setMemoryImg(null);
            setMemoryImgURL(null);
            setImgName("");
            setImgWarning(false);
        }
    }

    const handleSetAudio = (e) => {
        const audio = e.target.files?.[0];
        const maxDuration = 120;
        if (audio) {            
            if (audioFileTypes.test(audio.name)) {
                let audioFile = null;
                audioFile = document.createElement("audio");
                audioFile.src = URL.createObjectURL(audio);

                const onLoadedMetadata = () => {
                    const audioDuration = Math.floor(audioFile.duration);                    
                    if (audioDuration > maxDuration) {
                        setAudio(null);
                        setAudioURL(null);
                        setAudioWarning(true);
                        setAudioName("Please upload an audio clip that is less than 2 minutes in length.");
                    } else {
                        setAudio(e.target.files?.[0]);                    
                        setAudioURL(audioFile.src);
                        setAudioName(audio.name);
                        setAudioWarning(false);
                    }

                    audioFile.removeEventListener("loadedmetadata", onloadedmetadata);
                }

                audioFile.addEventListener("loadedmetadata", onLoadedMetadata);
            } else {                
                setAudio(null);
                setAudioURL(null);
                setAudioWarning(true);
                setAudioName("Please upload an audio clip of the correct file type.");
            }            
        } else {           
            setAudio(null);
            setAudioURL(null);
            setAudioWarning(false);
            setAudioName("");
        }
    }

    const ValidateForm = () => {
        //console.log(familyName, surname, ageValue, dayValue, monthValue, yearValue, conflictValue);
        if (familyName === "" || surname === "" || ageValue === "0" || dayValue === "" || monthValue === "" || yearValue === "" || conflictValue === "") {
            if (familyName === "") {
                setFamilyNameWarning(true);
            } else {
                setFamilyNameWarning(false);
            }

            if (surname === "") {
                setSurnameWarning(true);
            } else {
                setSurnameWarning(false);
            }

            if (ageValue === "0") {
                setAgeWarning(true);
            } else {
                setAgeWarning(false);
            }

            if (dayValue === "" || monthValue === "" || yearValue === "") {
                setDateWarning(true);
            } else {
                setDateWarning(false);
            }

            if (conflictValue === "") {
                setConflictWarning(true);
            } else {
                setConflictWarning(false);
            }     
            setFormWarning(true);
            //console.log("not validated...");            
            toggleCanPay(false);
        } else {
            setFamilyNameWarning(false);
            setSurnameWarning(false);
            setAgeWarning(false);
            setDateWarning(false);
            setConflictWarning(false);
            setFormWarning(false);
            //console.log("validated!");            
            toggleCanPay(true);
        }
    }

    const handleSubmit = (data, actions) => {   
        return actions.resolve();
    }

    const createOrder = (data, actions) => {
        const product = {
            description: "Rememberence card on Lost Echoes Online.",
            price: 5.00
        };

        return actions.order.create({
            purchase_units: [
                {
                    description: product.description,
                    amount: {
                        value: product.price
                    }
                }
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        });
    }

    const onApprove = async(data, actions) => {
        const order = await  actions.order.capture(); 
        //console.log("Order: ", order);
        if (order) {
            changeAppState("LOADING");
            setIsPaid(true);            
        }        
    }

    const onError = (err) => {    
        setIsPaid(false);
        changeAppState("FAILURE");
        console.error("PayPal checkout onError: " + err);
    }

    //console.log(familyName, surname, ageValue, dayValue, monthValue, yearValue, conflictValue);

    return { 
        familyName, 
        surname, 
        middleName, 
        age, 
        dayOption: day,
        monthOption: month, 
        yearOption: year,
        ageValue,
        dayValue,
        monthValue,
        yearValue,
        conflictOption: conflict,
        conflictValue,
        memoryImgURL,
        audioURL,
        imgName,
        audioName,
        familyNameWarning,
        surnameWarning,
        ageWarning,
        dateWarning,
        conflictWarning,
        imgWarning,
        audioWarning,
        formWarning,                
        canPay,
        handleFamilyName, 
        handleSurname, 
        handleMiddleName,
        handleSetAge, 
        handleSetDay,
        handleSetMonth,
        handleSetYear,
        handleSetConflict,
        handleSetMemoryImage,
        handleSetAudio,
        ValidateForm,
        handleSubmit,
        createOrder,
        onApprove,
        onError
    };
}