import { useRef } from "react";

import Text from "../elements/Text";
import ButtonLink from "../elements/ButtonLink";

import CookiePolicy from "./CookiePolicy";
import RefundPolicy from "./RefundPolicy";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";

import { useTestScrollToSection } from "../functions/TestFunctions";

export function useTermsPageContent() {
    const cookieRef = useRef(null);
    const refundsRef = useRef(null);
    const termsRef = useRef(null);
    const privacyRef = useRef(null);

    const {
        autoScroll
    } = useTestScrollToSection();

    const termsHeaderText = <Text 
        text="Terms and Policies"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.8rem"
        width="800px"
        visible={true}
    />

    const scrollToRefunds = <ButtonLink
        text="Refund Policy"
        font="Regular"
        fontsize="1.2rem"
        fontcolor="font-white"
        width="300"
        visible={true}
        onClick={() => autoScroll(refundsRef)}
    />

    const scrollToCookies = <ButtonLink
        text="Cookie Policy"
        font="Regular"
        fontsize="1.2rem"
        fontcolor="font-white"
        width="300"
        visible={true}
        onClick={() => autoScroll(cookieRef)}
    />

    const scrollToTerms = <ButtonLink
        text="Terms & Conditions of Service"
        font="Regular"
        fontsize="1.2rem"
        fontcolor="font-white"
        width="300"
        visible={true}
        onClick={() => autoScroll(termsRef)}
    />

    const scrollToPrivacy = <ButtonLink
        text="Privacy Statement"
        font="Regular"
        fontsize="1.2rem"
        fontcolor="font-white"
        width="300"
        visible={true}
        onClick={() => autoScroll(privacyRef)}
    />

    const cookies = <CookiePolicy cookieRef={cookieRef} />

    const refunds = <RefundPolicy refundRef={refundsRef} />

    const terms = <TermsAndConditions termRef={termsRef} />

    const privacy = <PrivacyPolicy 
        privacyRef={privacyRef} 
        cookiesRef={cookieRef}
    />

    return { 
        termsHeaderText,
        scrollToRefunds,
        scrollToCookies,
        scrollToTerms,
        scrollToPrivacy,
        cookies,
        refunds,
        terms,
        privacy
    }
}