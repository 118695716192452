import Text from "../elements/Text";
import MemoryForm from "./MemoryForm";

export function useMemoryPageContent() {
    const memoryText = <Text 
        text="This website is dedicated to remembering the ones lost to us by senseless warfare.
                    While we do not discourage honoring soldiers on this website, this site is more for honoring non-combatants that were senselessly killed due to being collateral damage, targeted specifically as part of a larger campaign of genocide, and more. 
                    This could include the elderly, children, or any person who was not drafted or participated in any military role."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const memoryText2 = <Text 
        text="To contribute to remembering innocent lives lost, please fill out the details below to create a remembrance card.
                    Adding a memory requires a contribution of $5(USD).                     
                    Refunds will not be given so please ensure the information you provide is correct to the best of your ability."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const memoryText3 = <Text 
        text="Thank you for visiting.
                    Please, do not pass up this chance to make a small gesture for those we have lost. 
                    Without you, we will not be able to remember them.
                    They were people with names, lives, families, jobs, and roles in society."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const memoryText4 = <Text 
        text="Let us forever remember them."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const memoryForm = <MemoryForm />

    return { memoryText, memoryText2, memoryText3, memoryText4, memoryForm };
}