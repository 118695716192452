import { useContext } from "react";

import Text from "../elements/Text";
import ButtonLink from "../elements/ButtonLink";
import ButtonText from "../elements/ButtonText";

import { useExternalLink } from "../functions/GenericFunctions";

import { AppStateContext } from "../contexts/AppStateContexts";

export function useCookiePolicyContent() {
    const {
        toggleCookies
    } = useContext(AppStateContext);

    const {
        openNewTab
    } = useExternalLink()

    const cookiePolicyHeader = <Text 
        text="Cookie Policy"
        font="SemiBold"
        fontcolor="font-white"
        fontsize="1.4rem"
        width="800px"
        visible={true}
    />

    const lastUpdatedText = <Text 
        text="Last Updated: 17 March 2024"
        font="MediumItalic"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const introductionHeader = <Text 
        text="Introduction"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const introductionText = <Text 
        text="This Cookie Policy explains how Lost Echoes Online by Aphrael Amarantha ('we,' 'us,' or 'our') uses cookies and similar technologies on our website.
                    By clicking 'Accept', you consent to the use of cookies in accordance with this policy."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookieDefinition = <Text 
        text="What are Cookies?"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const cookieDefinitionText = <Text 
        text="Cookies are small text files that are placed on your computer or mobile device when you visit a website.
                    Cookies allow websites to recognize your device, store your preferences, and improve your user experience."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookieTypes = <Text 
        text="Types of Cookies We Use"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const typeHeader1 = <Text 
        text="A. Essential Cookies"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const essentialCookiesText = <Text 
        text="We use essential cookies that are necessary for the proper functioning of our website.
                   These cookies are third-party cookies from PayPal and are required for the functionality of advanced checkout and purchasing features.
                   They are placed on your device by PayPal and are subject to PayPal's Privacy Policy."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const typeHeader2 = <Text 
        text="B. Third-Party Cookies"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const thirdPartyCookiesText = <Text 
        text="Apart from the essential cookies mentioned above, we do not use any additional third-party cookies."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookieSettings = <Text 
        text="Cookie Settings"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const cookieSettingsText = <Text 
        text="You can control and manage cookies in several ways:"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const settingsHeader1 = <Text 
        text="A. Cookie Consent"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookieConsentText = <Text 
        text="Upon your first visit to our website, you will be presented with a cookie banner requesting your consent to the use of cookies.
                    You can accept or reject essential cookies through the provided options."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const settingsHeader2 = <Text 
        text="B. Browser Settings"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const browserSettingsText = <Text 
        text="You can also manage cookies through your browser settings.
                    Most browsers allow you to block or delete cookies.
                    Please note that if you choose to block essential cookies, it may impact the functionality of our website."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const settingsHeader3 = <Text 
        text="C. Website Settings"
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const websiteSettingsText = <Text 
        text="Finally, in the event that you change your mind regarding the usage of cookies on the site, you can directly change the settings.
                   You can change your cookie settings directly by clicking on the 'Accept' or 'Decline' buttons found at the end of this cookie policy below.
                   Note that clicking on 'Accept' gives us explicit permission to use cookies on this website for the purpose of transferring your payment information to PayPal for processing."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const changeCookiePolicy = <Text 
        text="Changes to the Cookie Policy"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const changePolicyText = <Text 
        text="We may update this Cookie Policy from time to time to reflect changes in technology, data protection legislation, or our practices.
                    We encourage you to review this policy periodically.
                    It is your responisibilty to stay up to date to any changes made to this Cookie Policy.
                    We reserve the right to change this Cookie Policy at any time with or without your knowledge and with or without cause."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const contactUsHeader = <Text 
        text="Contact Us"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const xLink = <ButtonLink
        text="x.com/LostEchoesOnX"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://twitter.com/LostEchoesOnX")}
    />

    const blueSkyLink = <ButtonLink
        text="LostEchoesOnline.bsky.social"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://bsky.app/profile/lostechoesonline.bsky.social")}
    />

    const contactUsText = <Text 
        text={<>If you have any questions about our Cookie Policy, please contact us on X at {xLink} or on BlueSky at {blueSkyLink}.</>}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const additionalInfoHeader = <Text 
        text="Additional Information"
        font="Bold"
        fontcolor="font-white"
        fontsize="1.1rem"
        width="800px"
        visible={true}
    />

    const cookieLink = <ButtonLink
        text="www.allaboutcookies.org"
        font="Bold"
        fontsize="1.0rem"
        fontcolor="font-white"
        width=""
        visible={true}
        onClick={() => openNewTab("https://allaboutcookies.org/")}
    />

    const additionalInfoText = <Text 
        text={<>For more information about cookies, including how to see what cookies have been set and how to manage and delete them, visit {cookieLink}.</>}
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const cookieAgreementText = <Text 
        text="By clicking on 'Accept', you consent to the use of third-party cookies on this website for the purpose of using your personal financial data to process a payment for a contribution."
        font="Medium"
        fontcolor="font-white"
        fontsize="1.0rem"
        width="800px"
        visible={true}
    />

    const acceptButton = <ButtonText 
        text="Accept"
        visible={true}
        onClick={() => toggleCookies()}
    />

    const declineButton = <ButtonText 
        text="Decline"
        visible={true}
        onClick={() => toggleCookies()}
    />

    return {         
        cookiePolicyHeader, 
        lastUpdatedText,
        introductionHeader,
        introductionText,
        cookieDefinition,
        cookieDefinitionText,
        cookieTypes,
        typeHeader1,
        essentialCookiesText,
        typeHeader2,
        thirdPartyCookiesText,
        cookieSettings,
        cookieSettingsText,
        settingsHeader1,
        cookieConsentText,
        settingsHeader2,
        browserSettingsText,
        settingsHeader3,
        websiteSettingsText,
        changeCookiePolicy,
        changePolicyText,
        contactUsHeader,
        contactUsText,
        additionalInfoHeader,
        additionalInfoText,
        cookieAgreementText,
        acceptButton,
        declineButton
    }
}