import { useContext } from "react";

import { PayPalButtons } from "@paypal/react-paypal-js";

import { AppStateContext } from "../contexts/AppStateContexts";

export function usePayPalButtonContent({ onClick, createOrder, onApprove, onError }) {
    const {
        cookies
    } = useContext(AppStateContext);

    const paypalButton = cookies ? <PayPalButtons 
        style={
            {
                layout: "vertical",
                height: 48,   
                width: 300,             
                shape: "pill",                
            }
        }        
        forceReRender={onClick}
        onClick={onClick}
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={(err) => onError(err)}
    /> : null;

    return {paypalButton};
}