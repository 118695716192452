import { useTermsAndConditionsContent } from "./TermsAndConditions-Content";

const TermsAndConditions = ({ termRef }) => {
    const {
        termsHeader,
        lastUpdatedText,
        introHeader,
        introText,
        agreementHeader,
        agreementText1,
        agreementText2,
        agreementText3,
        agreementText4,
        agreementText5,
        ipHeader,
        ipText1,
        ipText2,
        userRepHeader,
        userRepText1,
        prohibitedHeader,
        prohibitedText1,
        prohibitedText2,
        prohibitedText3,
        userContentHeader,
        userContentText1,
        userContentText2,
        userContentText3,
        licenseHeader,
        licenseText1,
        licenseText2,
        licenseText3,
        licenseText4,
        submissionsHeader,
        SubmissionsText1,
        thirdpartyHeader,
        thirdpartyText1,
        adminHeader,
        adminText1,
        privacyHeader,
        privacyText1,
        copyrightHeader,
        copyrightText1,
        terminationHeader,
        terminationText1,
        terminationText2,
        modsHeader,
        modsText1,
        modsText2,
        lawHeader,
        lawText1,
        disputeHeader,
        disputeTitleA,
        disputeText1,
        disputeTitleB,
        disputeText2a,
        disputeText2b,
        disputeText2c,
        disputeTitleC,
        disputeText3,
        disputeTitleD,
        disputeText4,
        correctionsHeader,
        correctionsText,
        disclaimerHeader,
        disclaimerText,
        liabilityHeader,
        liabilityText,
        indemnHeader,
        indemnText,
        dataHeader,
        dataText,
        electronicHeader,
        electronicText,
        californiaHeader,
        californiaText,
        miscHeader,
        miscText,
        contactHeader,
        contactText,
        xText,
        xLink,
        blueskyText,
        blueskyLink,
        termlyText
    } = useTermsAndConditionsContent();

    const appearance = "flex vertical border-top top-pad50 gap10";

    const socialMediaAppearance = "flex horizontal-center gap20";

    const linksAppearance = "flex vertical pad10 gap5";

    return (
        <div ref={termRef} className={appearance}>
            {termsHeader}
            {lastUpdatedText}
            <br />
            {introHeader}
            {introText}
            <br />
            {agreementHeader}
            {agreementText1}
            {agreementText2}
            {agreementText3}
            {agreementText4}
            {agreementText5}
            <br />
            {ipHeader}
            {ipText1}
            {ipText2}
            <br />
            {userRepHeader}
            {userRepText1}
            <br />
            {prohibitedHeader}
            {prohibitedText1}
            {prohibitedText2}
            {prohibitedText3}
            <br />
            {userContentHeader}
            {userContentText1}
            {userContentText2}
            {userContentText3}
            <br />
            {licenseHeader}
            {licenseText1}
            {licenseText2}
            {licenseText3}
            {licenseText4}
            <br />
            {submissionsHeader}
            {SubmissionsText1}
            <br />
            {thirdpartyHeader}
            {thirdpartyText1}
            <br />
            {adminHeader}
            {adminText1}
            <br />
            {privacyHeader}
            {privacyText1}
            <br />
            {copyrightHeader}
            {copyrightText1}
            <br />
            {terminationHeader}
            {terminationText1}
            {terminationText2}
            <br />
            {modsHeader}
            {modsText1}
            {modsText2}
            <br />
            {lawHeader}
            {lawText1}
            <br />
            {disputeHeader}
            {disputeTitleA}
            {disputeText1}<br />
            {disputeTitleB}
            {disputeText2a}
            {disputeText2b}
            {disputeText2c}<br />
            {disputeTitleC}
            {disputeText3}<br />
            {disputeTitleD}
            {disputeText4}
            <br />
            {correctionsHeader}
            {correctionsText}
            <br />
            {disclaimerHeader}
            {disclaimerText}
            <br />
            {liabilityHeader}
            {liabilityText}
            <br />
            {indemnHeader}
            {indemnText}
            <br />
            {dataHeader}
            {dataText}
            <br />
            {electronicHeader}
            {electronicText}
            <br />
            {californiaHeader}
            {californiaText}
            <br />
            {miscHeader}
            {miscText}
            <br />
            {contactHeader}
            {contactText}<br />
            <div className={socialMediaAppearance} style={{minWidth: "100%"}}>
                <div className={linksAppearance}>
                    {xText}
                    {xLink}
                </div>
                <div className={linksAppearance}>
                    {blueskyText}
                    {blueskyLink}
                </div>
            </div>
            <br />
            {termlyText}
        </div>
    )
}

export default TermsAndConditions;