import { useEffect, useRef } from "react"

export const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        document.addEventListener("click", handleClick, true);

        return () => {
            document.removeEventListener("click", handleClick, true);
        }
    }, [callback]);

    return ref;
}

export const useRefreshPage = () => {
    window.location.reload();
}

export function useExternalLink() {
    const openNewTab = (href) => {
        window.open(href, "_blank", "noopener, noreferrer");
    }

    return { openNewTab }
}